import React from "react"
import tw from "twin.macro"
import styled from "styled-components"

import Main from "../components/layouts/Main"
import Header from "../components/layouts/Header"
import Row from "../components/layouts/Row"
import Footer from "../components/layouts/Footer"

const Title = styled.h1`
    ${tw`text-3xl md:text-5xl font-primary font-black dark:text-white text-gray-700 tracking-tight`}
    span {
        background: var(--pop-gradient);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
`

const Section = styled.section`
    ${tw`py-12 min-h-screen relative z-10 rounded-b-2rem bg-white dark:bg-black`}
    border-radius: 0 0 1rem 1rem;
`

const Terms = (props) => {
    return (
        <Main>
            <Header />
            <Section>
                <Row css={tw`mt-40`}>
                    <Title>Terms</Title>
                </Row>

                <Row>
                    <p>
                        IMPORTANT NOTICE: By using the Sidebrain's services,
                        features or activating use of Sidebrain services you
                        agree and accept to these terms and conditions and the
                        additional provisions below. You acknowledge that you
                        are above the legal age and that you are responsible for
                        all charges incurred by you while using the Service.
                        Please read the following terms carefully before
                        continuing to use this Service. If you do not agree with
                        any of these terms, please do not use this Service. If
                        you have any questions about these terms, please contact
                        hello@sidebrain.app This is a binding agreement between
                        you (“the User”) and Cuboft Marketing &amp; software
                        solutions private limited ("Sidebrain") for “Service”)
                        from a designated site in accordance with the use of
                        Sidebrain's products and related features (the Cuboft
                        Marketing &amp; software solutions Terms and Conditions
                        set forth below (the “Terms”) and any applicable
                        Sidebrain Service, Simply5’s Acceptable Use Policy and
                        such other policies as Sidebrain may adopt
                        (collectively, “Policies”), each as they may be amended
                        from time to time at the sole discretion of Sidebrain
                        and as may be posted from time to time at the Sidebrain
                        website at www.sidebrain.app. These Sidebrain Terms, any
                        Plan you have agreed to, and the Policies, including any
                        Sidebrain materials you received in connection with your
                        account, govern the use of the Service. In the event of
                        a direct inconsistency, except as otherwise expressly
                        stated in any of the foregoing, the Terms will control.
                        You do not have the authority to vary, alter or amend
                        any of the provisions of this Agreement. You agree
                        Sidebrain may change the provisions of the Agreement
                        from time to time, and agree that electronic notices to
                        you will be considered given and effective on the date
                        such notices are uploaded to the Sidebrain website. The
                        change(s) shall become binding on you from the date of
                        their posting as aforesaid and no further notice to be
                        given shall be required by Sidebrain.
                    </p>
                    <p>
                        Ipsum irure duis est anim magna fugiat duis adipisicing
                        enim in exercitation ea non aliquip. Lorem eu veniam
                        incididunt in aliqua occaecat consequat Lorem est
                        cupidatat ea ex. Dolor dolore ullamco labore deserunt
                        cupidatat reprehenderit deserunt cupidatat sit labore
                        magna id. Proident fugiat dolor ad cupidatat officia
                        exercitation reprehenderit aute commodo. Minim dolor do
                        occaecat consectetur ipsum proident exercitation quis
                        dolor mollit. Culpa consectetur est deserunt duis sunt
                        proident adipisicing ad excepteur enim. Veniam in sit
                        fugiat quis exercitation nulla commodo officia ipsum.
                    </p>
                </Row>
            </Section>
            <Footer />
        </Main>
    )
}

export default Terms
